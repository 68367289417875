<template lang="html">
  <div>
    <center>
      <h1>404</h1>
      <br />
      <h2>Not found</h2>
    </center>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped></style>
